import { FC } from "react";
import { IconProps } from "./IconProps";

export const SelectedIcon: FC<IconProps> = ({ className, strokeWidth }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={strokeWidth ?? 1.5}
    stroke="currentColor"
    className={`${className ? className : "h-6 w-6"}`}
  >
    <rect width="24" height="24" rx="4" fill="currentColor" />
    <path d="M12 16L8 12L9.41 10.59L12 13.17L16.59 8.58L18 10L12 16Z" fill="black" stroke="gray" strokeWidth="1" />
  </svg>
);
