import { TopNav } from "@components/TopNav";
import { useContext, useEffect, useState } from "react";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { useTranslation } from "@helpers/useTranslation";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FirebaseContext } from "src/helpers/firebase";
import { useEventId } from "src/helpers/useEvent";
import { usePersistedState } from "src/helpers/usePersistedState";
import { useToast } from "src/helpers/useToast";

export const PaymentsSuccess = () => {
  const { t } = useTranslation("admin");
  const eventId = useEventId();
  const [searchParams] = useSearchParams();
  const orderToken = searchParams.get("order-token");
  const orderId = searchParams.get("order-id");
  const sessionId = searchParams.get("session-id");
  const { auth, functions } = useContext(FirebaseContext);
  const [validateOrder, loadingOrder, errorOrder] = useHttpsCallable<
    API.ValidateOrderRequest,
    API.ValidateOrderResponse
  >(functions, "validateOrder");
  const [validateStripePayment, loadingStripe, errorStripe] = useHttpsCallable<
    API.ValidateStripePaymentRequest,
    API.ValidateOrderResponse
  >(functions, "validateStripePayment");
  const navigate = useNavigate();
  const [appData, setAppData] = usePersistedState();
  const toast = useToast();
  const [seconds, setSeconds] = useState(7);
  const [isValidated, setValidated] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const returnBack = () => {
    const redirectBackUrl = appData?.redirectBackUrl;
    setAppData({
      ...appData,
      uid: auth.currentUser?.uid,
      isAdmin: true,
    });

    redirectBackUrl ? navigate(redirectBackUrl) : navigate(`/${eventId}/admin`);
  };

  useEffect(() => {
    if (seconds <= 0) returnBack();
  }, [seconds]);

  useEffect(() => {
    if (isValidated) return;

    if (orderToken) {
      validateMontonioPayment();
    } else if (sessionId) {
      validateStripeCheckout();
    }
  }, [orderToken, sessionId, validateOrder, validateStripePayment]);

  const validateMontonioPayment = async () => {
    try {
      const result = await validateOrder({ orderToken, orderId, eventId });
      const valid = result?.data?.valid ?? false;
      if (valid) {
        toast(t("paymentSuccess"), "🎉", "success");
        setValidated(true);
      }
    } catch (error) {
      console.error("Error occurred during order validation", error);
      toast(t("somethingWentWrong"), "🤷‍♂️", "error");
    }
  };

  const validateStripeCheckout = async () => {
    try {
      // Use the session ID from the URL if available, otherwise use the one from local storage
      const sessionIdToUse = sessionId;
      if (!sessionIdToUse) {
        console.error(`No Stripe session ID found for event ${eventId}, sessionId: ${sessionId}}`);
        return;
      }

      const result = await validateStripePayment({ eventId, sessionId: sessionIdToUse });
      const valid = result?.data?.valid ?? false;
      if (valid) {
        toast(t("paymentSuccess"), "🎉", "success");
        setValidated(true);
      }
    } catch (error) {
      console.error("Error occurred during Stripe payment validation", error);
      toast(t("somethingWentWrong"), "🤷‍♂️", "error");
    }
  };

  useEffect(() => {
    if (errorOrder || errorStripe) {
      console.error("Error occurred during payment validation", errorOrder || errorStripe);
      toast(t("somethingWentWrong"), "🤷‍♂️", "error");
    }
  }, [errorOrder, errorStripe]);

  const error = errorOrder || errorStripe;
  const loading = loadingOrder || loadingStripe;

  const renderContent = () => {
    if (error) {
      return (
        <div className="flex flex-col items-center justify-center">
          <h1 className="mt-12 text-center text-xl">{t("somethingWentWrong")}</h1>
          <button onClick={returnBack} className="btn-primary btn-ghost btn mt-12">{`${t(
            "goBackFromPayments",
          )} ${seconds}`}</button>
        </div>
      );
    }

    if (loading) {
      return (
        <div className="flex flex-col items-center justify-center">
          <h1 className="mt-12 text-center text-2xl">{t("validatingPayment")}</h1>
          <div className="mt-4 text-center text-lg">{t("pleaseWait")}</div>
        </div>
      );
    }

    return (
      <div className="flex flex-col items-center justify-center">
        <h1 className="mt-12 text-center text-2xl">{t("paymentSuccess")}</h1>
        <div className="mt-4 text-center text-lg">{t("confirmPremium")}</div>
        <button onClick={returnBack} className="btn-primary btn-ghost btn mt-12">{`${t(
          "goBackFromPayments",
        )} ${seconds}`}</button>
      </div>
    );
  };

  return (
    <div className="flex flex-col">
      <TopNav isAdmin />
      <div className="flex h-screen flex-col overflow-hidden">
        <main className="flex-1 overflow-y-scroll p-4">{renderContent()}</main>
      </div>
    </div>
  );
};
