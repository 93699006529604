import { Footer } from "@components/Footer";
import { Link } from "@components/Link";
import { TopNav } from "@components/TopNav";
import { doc, setDoc } from "firebase/firestore";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "@helpers/useTranslation";
import { useParams } from "react-router-dom";
import { FirebaseContext } from "src/helpers/firebase";
import { useChallenge } from "src/helpers/useChallenge";
import { useConfetti } from "src/helpers/useConfetti";
import { useEventNavigate } from "src/helpers/useEventNavigate";
import { useSolution } from "src/helpers/useSolution";
import { useTeam } from "src/helpers/useTeam";
import { useToast } from "src/helpers/useToast";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { useTranslatedKey } from "@helpers/useTranslatedKey";

export const ChallengeQuestion: FC = () => {
  const { t } = useTranslation("challenges");
  const { firestore, functions } = useContext(FirebaseContext);
  const [updateTeamPoints, _, error] = useHttpsCallable<API.UpdateTeamPointsRequest, API.UpdateTeamPointsResponse>(
    functions,
    "updateTeamPoints",
  );
  const toast = useToast();
  const navigate = useEventNavigate();
  useConfetti("submit-button");

  useEffect(() => {
    if (error) {
      toast(t("problemOccured"), "🤷‍♂️", "error");
      console.error("Problem occured in updateTeamPoints function: ", error);
    }
  }, [error]);

  const { eventId, challengeId, pid } = useParams();
  const questionId = parseInt(pid);

  const [challenge] = useChallenge<QuizChallenge>();
  const [team] = useTeam();
  const [solution] = useSolution<QuizSolution>();

  const question = useMemo(() => {
    if (!challenge) return;
    return challenge.questions[questionId];
  }, [challenge, questionId]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!challenge) return;

    const lowerBound = 0;
    const upperBound = (challenge.questions ?? []).length - 1;
    const wrongUrl = typeof pid !== "string" || questionId < lowerBound || questionId > upperBound;
    if (wrongUrl || solution?.completed) navigate("/challenges");
  }, [challenge]);

  const { getTranslatedValue } = useTranslatedKey();
  const questionCopy = getTranslatedValue(question, "question");
  const answer0 = getTranslatedValue(question, "answer0");
  const answer1 = getTranslatedValue(question, "answer1");
  const answer2 = getTranslatedValue(question, "answer2");
  const answer3 = getTranslatedValue(question, "answer3");

  if (!challenge) return <></>;

  const updateAnswer = async (answerId: number) => {
    const newAnswers = [...(solution?.answers ?? [])];
    newAnswers[questionId] = answerId;

    const teamId = team.id;
    const docRef = doc(firestore, `events/${eventId}/teams/${teamId}/solutions/${challengeId}`);
    await setDoc(docRef, { answers: newAnswers }, { merge: true });
  };

  const answer = solution?.answers ? solution.answers[questionId] : undefined;
  const isAnswered = (answerId: number) => answer === answerId;
  const hasAnswer = answer === undefined || answer === null ? false : true;

  const previousQuestionId = questionId > 0 ? questionId - 1 : null;
  const nextQuestionId = questionId < challenge.questions.length - 1 ? questionId + 1 : null;
  const lastQuestion = questionId === challenge.questions.length - 1;

  const submit = async () => {
    setIsSubmitting(true);
    if (!team) {
      toast(t("problemOccured"), "🤷‍♂️", "error");
      console.error(`Team is undefined. Team: ${team}, event: ${eventId}`);
      return;
    }

    try {
      const teamId = team.id;
      updateTeamPoints({ teamId, challengeId, eventId, solutionType: "quiz" }); // Not awaiting on purpose for better UX
    } catch (error) {
      console.error(error);
    }

    setIsSubmitting(false);
    navigate("/challenges");
  };

  if (!question) return <></>;

  return (
    <main>
      <TopNav showBack />
      <div className="h-screen px-6 py-4">
        <div className="mt-4 mb-8 flex flex-col items-center">
          <h2 className="my-10 px-6 text-center text-2xl font-semibold">{questionCopy}</h2>
          <div className="mx-6">
            <div className="mb-4 flex justify-center">
              <button
                className={`btn max-w-xs ${isAnswered(0) ? "btn-primary" : "btn-outline btn-secondary btn"}`}
                onClick={() => updateAnswer(0)}
              >
                {answer0}
              </button>
            </div>
            <div className="mb-4 flex justify-center">
              <button
                className={`btn max-w-xs ${isAnswered(1) ? "btn-primary" : "btn-outline btn-secondary btn"}`}
                onClick={() => updateAnswer(1)}
              >
                {answer1}
              </button>
            </div>
            <div className="mb-4 flex justify-center">
              <button
                className={`btn max-w-xs ${isAnswered(2) ? "btn-primary" : "btn-outline btn-secondary btn"}`}
                onClick={() => updateAnswer(2)}
              >
                {answer2}
              </button>
            </div>
            <div className="mb-4 flex justify-center">
              <button
                className={`btn max-w-xs ${isAnswered(3) ? "btn-primary" : "btn-outline btn-secondary btn"}`}
                onClick={() => updateAnswer(3)}
              >
                {answer3}
              </button>
            </div>
            <div className="my-12 mb-12 flex justify-center">
              {previousQuestionId !== null && (
                <Link to={`challenges/${challengeId}/${previousQuestionId}`}>
                  <button className="btn-outline btn-primary btn mx-4">{t("back")}</button>
                </Link>
              )}
              {nextQuestionId && (
                <button
                  disabled={!hasAnswer}
                  onClick={() => {
                    if (hasAnswer) navigate(`/challenges/${challengeId}/${nextQuestionId}`);
                  }}
                  className={`btn-primary btn btn mx-4 ${hasAnswer ? "" : "pointer-events-none touch-none"}`}
                >
                  {t("next")}
                </button>
              )}
              <button
                disabled={!hasAnswer || isSubmitting}
                onClick={submit}
                className={`btn-primary btn ${lastQuestion ? "" : "hidden"}`}
                id="submit-button"
              >
                {t("submit")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};
