import { useState, useEffect } from "react";

export const useCountry = () => {
  const [country, setCountry] = useState("US"); // Default fallback

  useEffect(() => {
    const detectCountry = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();
        setCountry(data.country_code);
      } catch (error) {
        console.error("Error detecting country:", error);
      }
    };

    detectCountry();
  }, []);

  return { country, setCountry };
};
