import { LanguageIcon } from "@components/Icons/Language";
import { getLocaleFlag, useLocales } from "@helpers/locales";
import { usePersistedState } from "@helpers/usePersistedState";
import { useTranslation } from "react-i18next";
import { useBranding } from "src/helpers/useBranding";

export const Contact = () => {
  const { emailDomain } = useBranding();
  const { t, i18n } = useTranslation("contact");
  const [appData, setAppData] = usePersistedState();
  const locales = useLocales(true);

  return (
    <div
      style={{ backgroundImage: `url(/bg-wedding.webp)` }}
      className={`h-screen justify-center overflow-hidden bg-slate-800 bg-cover bg-center bg-blend-overlay`}
    >
      <div className="flex justify-center px-4">
        <div className="flex flex-col">
          <div className="mt-16 mr-1 flex justify-end">
            <div className="flex items-center">
              <div className="dropdown-end dropdown">
                <label tabIndex={0} className="btn-primary btn-ghost btn-circle btn">
                  <LanguageIcon className="hidden" />
                  <div className="text-lg">{getLocaleFlag(i18n.language as AppLocale)}</div>
                </label>
                <ul
                  tabIndex={0}
                  className="dropdown-content menu rounded-box menu-compact mt-3 border border-base-300 bg-base-200 p-2 shadow"
                >
                  {locales.length > 1 && (
                    <li className="flex justify-center align-bottom">
                      {locales.map((locale) => (
                        <button
                          key={locale}
                          className={`my-1 flex items-center rounded-lg border-2 border-base-300 bg-base-200 ${
                            locale === i18n.language ? "border-primary font-semibold underline" : ""
                          }}`}
                          onClick={() => {
                            i18n.changeLanguage(locale);
                            setAppData({ ...appData, locale });
                            (document?.activeElement as any)?.blur();
                          }}
                        >
                          <span className="text-xs uppercase text-neutral">{locale}</span>
                          <span className="text-lg">{getLocaleFlag(locale)}</span>
                        </button>
                      ))}
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="mb-4 flex max-w-sm flex-col justify-between rounded-lg bg-slate-100 p-6">
            <div>
              <div className="px-8 pt-8 pb-4 text-center font-bold">
                <h3 className="text-lg font-bold">{t("getInTouch")}</h3>
                <p className="py-4">
                  <span className="mb-4 font-light">{t("leaveYourMessage")}</span>
                </p>
              </div>
              <form
                className="text-center"
                name="contact"
                method="POST"
                data-netlify="true"
                netlify-honeypot="bot-field"
              >
                <input type="hidden" name="form-name" value="contact" />
                <div className="hidden">
                  <label>
                    Don't fill this out if you're human: <input name="bot-field" />
                  </label>
                </div>
                <label className="label" htmlFor="email-input">
                  {t("yourEmail")}
                  <span className="text-warning">*</span>
                </label>
                <input
                  id="email-input"
                  name="email"
                  type="email"
                  required
                  placeholder="e.g.: johndoe@example.com"
                  className="contact-input input-bordered input mb-4 w-full"
                />
                <label className="label" htmlFor="message-input">
                  {t("yourMessage")}
                  <span className="text-warning">*</span>
                </label>
                <textarea
                  id="message-input"
                  name="message"
                  required
                  rows={12}
                  placeholder={t("messagePlaceholder")}
                  className="contact-input input-bordered input mb-4 h-32 w-full pt-2"
                />
                <button type="submit" className="btn-primary btn ml-4 mt-4">
                  {t("contact")}
                </button>
              </form>
            </div>
            <div className="mt-12 flex flex-col items-center justify-center text-sm text-gray-600">
              <div className="font-semibold">{t("orReachOutDirectly")}</div>
              <span className="blockspam" aria-hidden="true">
                __
              </span>{" "}
              hello@
              <span className="blockspam" aria-hidden="true">
                ----
              </span>
              {emailDomain}
              <div className="mt-4">
                <a href="https://www.instagram.com/tuokisapp">
                  <span className="text-primary underline">@tuokisapp</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
