// Base price in EUR
export const BASE_PRICE_EUR = 49.9;

// Market-specific pricing (in their respective currencies)
export const MARKET_PRICES: Record<string, number> = {
  EUR: 49.9, // Base price for EU
  GBP: 49.9, // UK
  USD: 49.9, // US
  CHF: 49.9, // Switzerland
  PLN: 199.9, // Poland (adjusted for purchasing power)
  AUD: 79.9, // Australia
  NZD: 79.9, // New Zealand
};

// Currency mapping for different countries
export const CURRENCY_MAP: Record<string, string> = {
  GB: "GBP",
  US: "USD",
  PL: "PLN",
  CH: "CHF",
  AU: "AUD",
  NZ: "NZD",
  // Add EUR for all EU countries
  LT: "EUR",
  LV: "EUR",
  EE: "EUR",
  DE: "EUR",
  FR: "EUR",
  IT: "EUR",
  ES: "EUR",
  PT: "EUR",
  NL: "EUR",
  BE: "EUR",
  IE: "EUR",
  DK: "EUR",
  SE: "EUR",
  FI: "EUR",
  AT: "EUR",
  GR: "EUR",
  HU: "EUR",
  CZ: "EUR",
  SK: "EUR",
  SI: "EUR",
  CY: "EUR",
  MT: "EUR",
  LU: "EUR",
  HR: "EUR",
  RO: "EUR",
  BG: "EUR",
};

// Currency symbol mapping
export const CURRENCY_SYMBOL_MAP: Record<string, string> = {
  GBP: "£",
  USD: "$",
  PLN: "zł",
  CHF: "CHF",
  AUD: "A$",
  NZD: "NZ$",
  EUR: "€",
};
