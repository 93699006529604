import { FirebaseStorage } from "firebase/storage";

// Cache for storing downloaded URLs
const urlCache = new Map<string, string>();

/**
 * Gets the download URL for a media (image/video)from Firebase storage with caching
 * @param storage Firebase storage instance
 * @param uri Path to video in storage
 * @returns The download URL for the media file in Cloud Storage
 */
export const getMediaUrl = (storage: FirebaseStorage, uri: string): string => {
  if (urlCache.has(uri)) return urlCache.get(uri) || "";

  try {
    const bucket = storage.app.options.storageBucket;

    // Check if running in emulator
    const useEmulator = process.env.REACT_APP_FIREBASE_EMULATE === "TRUE";

    let url = "";
    if (useEmulator) {
      // Emulator URL format
      const emulatorHost = process.env.REACT_APP_FIREBASE_STORAGE_HOST || "localhost:9199";
      url = `http://${emulatorHost}/v0/b/${bucket}/o/${encodeURIComponent(uri)}?alt=media`;
    } else {
      // Production URL format
      url = `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${encodeURIComponent(uri)}?alt=media`;
    }

    urlCache.set(uri, url);
    return url;
  } catch (error) {
    console.error(error);
    return "";
  }
};
