import { useEffect, useState } from "react";

export const useIsCamdeed = () => {
  const [isCamdeed, setIsCamdeed] = useState<boolean | undefined>(undefined);
  useEffect(() => {
    const localhostInUrl = window.location.href.includes("localhost") || window.location.href.includes("127.0.0.1");
    const camdeedInUrl = window.location.href.includes("camdeed.app") || window.location.href.includes("camdeed.com");
    const isCamdeed = localhostInUrl || camdeedInUrl; // use Camdeed in localhost as well
    setIsCamdeed(isCamdeed);

    // Ensure we have the right theme set
    if (camdeedInUrl) {
      const htmlElement = document.querySelector("html");
      const dataThemeValue = htmlElement.getAttribute("data-theme");
      const hasCamdeedTheme = dataThemeValue.includes("camdeed");
      if (!hasCamdeedTheme) htmlElement.setAttribute("data-theme", "camdeed");
    }

    const tuokisInUrl = window.location.href.includes("tuokis.app") || window.location.href.includes("tuokis.com");
    if (tuokisInUrl) {
      const htmlElement = document.querySelector("html");
      const dataThemeValue = htmlElement.getAttribute("data-theme");
      const hasTuokisTheme = dataThemeValue.includes("tuokis");
      if (!hasTuokisTheme) htmlElement.setAttribute("data-theme", "tuokis");
    }
  }, []);

  return isCamdeed;
};
