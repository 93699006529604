import { FirebaseCachedImage } from "@components/FirebaseCachedImage";
import { BrandIcon } from "@components/Icons/BrandIcon";
import { Loading } from "@components/Loading";
import { TopNav } from "@components/TopNav";
import { FirebaseContext } from "@helpers/firebase";
import { useBranding } from "@helpers/useBranding";
import { useTranslation } from "@helpers/useTranslation";
import { useContext, useEffect, useState } from "react";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { useNavigate } from "react-router-dom";

export const MyEvents = () => {
  const { t } = useTranslation("admin");
  const { functions } = useContext(FirebaseContext);
  const [getMyEvents, loading] = useHttpsCallable<API.GetMyEventsRequest, API.GetMyEventsResponse>(
    functions,
    "getMyEvents",
  );

  const [myEvents, setMyEvents] = useState<MyEvent[]>([]);
  const { appDomain } = useBranding();

  useEffect(() => {
    getMyEvents().then((result) => {
      setMyEvents(result.data);
    });
  }, []);

  const navigate = useNavigate();
  return (
    <>
      <TopNav isAdmin={true} useAllLocales hideUpgrade />
      <main className="flex-1 flex-col">
        <div className="mb-24 flex flex-col items-center py-4 px-6">
          <Loading loading={loading}>
            <div className="flex w-full max-w-lg flex-col">
              <h1 className="mt-12 mb-8 text-center text-4xl font-semibold">{t("myEvents")}</h1>
              <p className="mb-6 text-center text-lg font-light">{t("aboutMyEvents")}</p>

              {myEvents.length === 0 && <div className="text-center text-xl font-semibold">{t("noEventsYet")}</div>}
              {myEvents.map((event) => (
                <a className="group hover:cursor-pointer" onClick={() => navigate(`/${event.id}/admin`)} key={event.id}>
                  <div
                    className="card card-side mb-8 rounded-lg border border-base-300 bg-base-200 py-2 shadow-xl"
                    key={event.id}
                  >
                    <div className="avatar ml-4 items-center">
                      <div className="h-24 w-24 rounded-full opacity-90 ring ring-base-300 ring-offset-2 ring-offset-base-100 group-hover:opacity-100 group-hover:ring-primary">
                        <FirebaseCachedImage
                          uri={`events/${event.id}/background_light`}
                          defaultUri={event?.type === "wedding" ? "bg-wedding.webp_light" : "bg-conference.webp_light"}
                          alt=""
                        />
                      </div>
                    </div>

                    <div className="overflow-ios-fix card-body overflow-x-scroll py-4">
                      <h1 className="card-title mr-4 max-h-32 pr-4">{event.name}</h1>
                      <div className="mt-2 max-h-32">
                        {appDomain}/{event.id}
                      </div>
                      <div className="mt-2 max-h-32 text-sm font-light">{event.date}</div>
                    </div>

                    <span
                      className="pointer-events-none absolute right-6 top-6 text-gray-200 text-gray-300 group-hover:text-gray-700"
                      aria-hidden="true"
                    >
                      <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                      </svg>
                    </span>
                  </div>
                </a>
              ))}
              <div className="mb-8 flex justify-center">
                <button
                  onClick={() => navigate("/addevent")}
                  className="btn-outline btn-primary btn my-6 h-10 w-28 text-lg"
                  type="button"
                >
                  +
                </button>
              </div>
              <div className="mb-12 flex justify-center">
                <button className={`btn-link btn flex flex-row `} onClick={() => navigate("/branding")}>
                  <BrandIcon />
                  <span>{t("myBranding")}</span>
                </button>
              </div>
            </div>
          </Loading>
        </div>
      </main>
    </>
  );
};
