import { DiscountInput } from "@components/DiscountInput";
import { BankingIcon } from "@components/Icons/Banking";
import { CheckIcon } from "@components/Icons/Check";
import { CreditCardIcon } from "@components/Icons/CreditCard";
import { SpinnerIcon } from "@components/Icons/Spinner";
import { PoppersIcon } from "@components/Icons/Poppers";
import { Modal } from "@components/Modal";
import { FC, useContext, useEffect, useState, useMemo } from "react";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { useTranslation } from "@helpers/useTranslation";
import { FirebaseContext } from "src/helpers/firebase";
import { useEventId } from "src/helpers/useEvent";
import { usePersistedState } from "src/helpers/usePersistedState";
import { useToast } from "src/helpers/useToast";
import { useBranding } from "@helpers/useBranding";
import { useCountry } from "@helpers/useCountry";
import { CURRENCY_MAP, CURRENCY_SYMBOL_MAP, MARKET_PRICES, BASE_PRICE_EUR } from "./currencies";

type Props = {
  show: boolean;
  redirectBackUri?: string;
  onClose?: () => void;
  fallbackEventId?: string;
};

export const PremiumModal: FC<Props> = ({ show, onClose, redirectBackUri, fallbackEventId }) => {
  onClose = onClose ?? (() => {});
  const { t, i18n } = useTranslation("admin");
  const eventIdFromUrl = useEventId();
  const eventId = eventIdFromUrl ?? fallbackEventId;
  const [appData, setAppData] = usePersistedState();
  const { functions } = useContext(FirebaseContext);
  const [loadingPaymentMethods, setLoadingPaymentMethods] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState<Payments.PaymentStep>("info");
  const [discountAmount, setDiscountAmount] = useState<number | null>(null);
  const [discountCode, setDiscountCode] = useState<string | null>(null);
  const [showDiscountInput, setShowDiscountInput] = useState<boolean>(false);
  const { brand } = useBranding();
  const toast = useToast();
  const { country, setCountry } = useCountry();
  const locale = (i18n.language || "en") as AppLocale;

  const currency = CURRENCY_MAP[country] || "EUR";
  const currencySymbol = CURRENCY_SYMBOL_MAP[currency] || currency;
  const marketPrice = MARKET_PRICES[currency] || BASE_PRICE_EUR;

  useEffect(() => {
    if (discountAmount) {
      setShowDiscountInput(false);
    }
  }, [discountAmount]);

  const [getPaymentMethods] = useHttpsCallable<unknown, API.GetPaymentMethodsResponse>(functions, "getPaymentMethods");
  const [newOrder, newOrderLoading] = useHttpsCallable<API.NewOrderRequest, API.NewOrderResponse>(
    functions,
    "newOrder",
  );
  const [stripePayment, stripePaymentLoading] = useHttpsCallable<API.NewOrderRequest, API.NewOrderResponse>(
    functions,
    "stripePayment",
  );
  const [paymentMethods, setPaymentMethods] = useState<Payments.PaymentMethod[]>([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<Payments.PaymentMethod | null>(null);

  const onGetPaymentMethods = async () => {
    setLoadingPaymentMethods(true);
    try {
      const paymentMethodsResponse = await getPaymentMethods();
      const paymentMethods = paymentMethodsResponse.data.paymentMethods.paymentInitiation.setup.LT.paymentMethods;
      paymentMethods.sort((a, b) => a.uiPosition - b.uiPosition);
      setPaymentMethods(paymentMethods);
    } catch (error) {
      console.error(error);
      toast(t("errorGettingPaymentMethods"), "🤷‍♂️", "error");
    } finally {
      setLoadingPaymentMethods(false);
    }
  };

  const onPaymentMethodSelect = (paymentMethodName: string) => {
    const paymentMethod = paymentMethods.find((paymentMethod) => paymentMethod.name === paymentMethodName);
    if (paymentMethod) setSelectedPaymentMethod(paymentMethod);
  };

  const onPay = async () => {
    try {
      setLoading(true);

      const isStripePayment = paymentType === "stripe";
      if (isStripePayment) {
        const result = await stripePayment({
          eventId,
          discountCode,
          brand,
          expectedAmount: round(discountedAmount) || marketPrice,
          paymentMethod: { name: "Stripe", code: "stripe" } as Payments.StripePaymentMethod,
          paymentType,
          locale,
          currency,
        });

        const paymentUrl = result?.data?.paymentUrl;
        const orderId = result?.data?.orderId;
        const stripeSessionId = result?.data?.stripeSessionId;

        if (paymentUrl && orderId && stripeSessionId) {
          const uri = redirectBackUri ?? window?.location?.pathname;
          setAppData({ ...appData, stripeSessionId, orderId, redirectBackUrl: uri }, eventId);

          // give extra time to persist order id (precaution) before redirecting
          await new Promise((resolve) => setTimeout(resolve, 100));

          window.location.href = paymentUrl;
        } else {
          console.error(`No Stripe redirect url from payment response. EventId: ${eventId}`);
          toast(t("errorCreatingOrder"), "🤷‍♂️", "error");
        }
      }

      const isMontonioPayment = !isStripePayment;
      if (isMontonioPayment) {
        const result = await newOrder({
          eventId,
          discountCode,
          brand,
          expectedAmount: round(discountedAmount) || marketPrice,
          paymentMethod: selectedPaymentMethod,
          paymentType,
          locale,
        });
        const redirectUrl = result?.data?.paymentUrl;
        const orderUuid = result?.data?.orderUuid;
        const orderId = result?.data?.orderId;

        if (redirectUrl && orderUuid && orderId) {
          const uri = redirectBackUri ?? window?.location?.pathname;
          setAppData({ ...appData, orderUuid, orderId, redirectBackUrl: uri }, eventId);

          // give extra time to persist order id (precaution) before redirecting
          await new Promise((resolve) => setTimeout(resolve, 100));

          window.location.href = redirectUrl;
        } else {
          console.error(`No redirect url from payment response. EventId: ${eventId}`);
          toast(t("errorCreatingOrder"), "🤷‍♂️", "error");
        }
      }
    } catch (error) {
      console.error(error);
      toast(t("errorCreatingOrder"), "🤷‍♂️", "error");
    } finally {
      setLoading(false);
    }
  };

  const [paymentType, setPaymentType] = useState<Payments.PaymentType | undefined>();
  const onUpgrade = () => setStep("upgrade");

  useEffect(() => {
    if (show) onGetPaymentMethods();
  }, [show]);

  const discountedAmount = discountAmount ? marketPrice * (1 - discountAmount) : null;
  const round = (price: number) => Math.round(price * 100) / 100;
  const formatPercentage = (percentage: number) => `${Math.round(percentage * 100)}%`;
  const formatPrice = (price: number) => `${currencySymbol}${round(price).toFixed(2)}`;

  const useStripe = useMemo(() => {
    const montonioCountries = ["LT", "LV", "EE"];
    const useMontonio = montonioCountries.includes(country);
    useMontonio ? setPaymentType("eBanking") : setPaymentType("stripe");
    return !useMontonio;
  }, [country]);

  return (
    <Modal show={show} onCancel={onClose} hideX>
      {step === "info" && (
        <div className="px-2 pt-4">
          <h3 className="mb-4 text-xl font-thin">{t("premiumBenefits")}</h3>
          <div className="flex flex-col">
            <ul className="w-full list-inside list-disc">
              <li className="flex w-full max-w-sm justify-between p-1">
                <span>{t("premiumBenefit1")}</span>
                <span>
                  <CheckIcon />
                </span>
              </li>
              <li className="flex w-full max-w-sm justify-between p-1">
                <span>{t("premiumBenefit2")}</span>
                <span>
                  <CheckIcon />
                </span>
              </li>
              <li className="flex w-full max-w-sm justify-between p-1">
                <span>{t("premiumBenefit3")}</span>
                <span>
                  <CheckIcon />
                </span>
              </li>
              <li className="flex w-full max-w-sm justify-between p-1">
                <span>{t("premiumBenefit4")}</span>
                <span>
                  <CheckIcon />
                </span>
              </li>
              <li className="flex w-full max-w-sm justify-between p-1">
                <span>{t("premiumBenefit5")}</span>
                <span>
                  <CheckIcon />
                </span>
              </li>
              <li className="flex w-full max-w-sm justify-between p-1">
                <span>{t("premiumBenefit6")}</span>
                <span>
                  <CheckIcon />
                </span>
              </li>
            </ul>
            <button
              type="button"
              className="btn-primary btn mt-8 mb-3 flex inline-flex w-full items-center justify-center rounded-md bg-primary px-3 py-3 text-sm font-semibold text-white shadow-sm hover:border-base-100 hover:bg-base-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-base-300 sm:col-start-2"
              onClick={onUpgrade}
            >
              <PoppersIcon className="mr-4 h-4 w-4" />
              {t("upgradeNow")}
            </button>
          </div>
        </div>
      )}
      {step === "upgrade" && (
        <div className="overscroll-none">
          <h2 className="mt-8 mb-4 w-full pb-8 text-center text-3xl font-bold ">{t("paymentMethod")}</h2>
          <div className="mb-6">
            <label htmlFor="country" className="label">
              {t("country")}
            </label>
            <select
              id="country"
              value={country}
              className="select-bordered select w-full px-4"
              onChange={(e) => setCountry(e.target.value)}
            >
              <option value="LT">Lithuania</option>
              <option value="LV">Latvia</option>
              <option value="EE">Estonia</option>
              <option value="PL">Poland</option>
              <option value="US">United States</option>
              <option value="GB">United Kingdom</option>
              <option value="CA">Canada</option>
              <option value="AU">Australia</option>
              <option value="NZ">New Zealand</option>
              <option value="JP">Japan</option>
              <option value="CH">Switzerland</option>
              <option value="SE">Sweden</option>
              <option value="NO">Norway</option>
              <option value="IS">Iceland</option>
              <option value="FI">Finland</option>
              <option value="AT">Austria</option>
              <option value="BE">Belgium</option>
              <option value="BG">Bulgaria</option>
              <option value="HR">Croatia</option>
              <option value="CY">Cyprus</option>
              <option value="CZ">Czech Republic</option>
              <option value="DK">Denmark</option>
              <option value="FR">France</option>
              <option value="DE">Germany</option>
              <option value="GR">Greece</option>
              <option value="HU">Hungary</option>
              <option value="IE">Ireland</option>
              <option value="IT">Italy</option>
              <option value="LU">Luxembourg</option>
              <option value="MT">Malta</option>
              <option value="NL">Netherlands</option>
              <option value="PT">Portugal</option>
              <option value="RO">Romania</option>
              <option value="SK">Slovakia</option>
              <option value="SI">Slovenia</option>
              <option value="ES">Spain</option>
              <option value="OTHER">Other</option>
            </select>
          </div>

          <div className="card w-full bg-white p-6">
            {!useStripe ? (
              <>
                {/* Montonio payment options */}
                <div className="form-control flex flex-row items-center justify-between">
                  <span className="form-control flex flex-row items-center justify-between">
                    <input
                      onClick={() => setPaymentType("eBanking")}
                      id="ebanking-payment"
                      type="radio"
                      name="payment-type"
                      className="radio checked:bg-primary"
                      defaultChecked
                    />
                    <label htmlFor="ebanking-payment" className="label ml-2 cursor-pointer">
                      <span className="label-text">{t("eBanking")}</span>
                    </label>
                  </span>
                  <BankingIcon />
                </div>
                <div className="form-control flex flex-row items-center justify-between">
                  <span className="form-control flex flex-row items-center justify-between">
                    <input
                      onClick={() => setPaymentType("creditCard")}
                      id="credit-card-payment"
                      type="radio"
                      name="payment-type"
                      className="radio checked:bg-primary"
                    />
                    <label htmlFor="credit-card-payment" className="label ml-2 cursor-pointer">
                      <span className="label-text">{t("creditCard")}</span>
                    </label>
                  </span>
                  <CreditCardIcon />
                </div>
                <div className="form-control flex flex-row items-center justify-between">
                  <span className="form-control flex flex-row items-center justify-between">
                    <input
                      onClick={() => setPaymentType("mobilePay")}
                      id="apple-pay-payment"
                      type="radio"
                      name="payment-type"
                      className="radio checked:bg-primary"
                    />
                    <label htmlFor="apple-pay-payment" className="label ml-2 cursor-pointer">
                      <span className="label-text">{t("applePay")}</span>
                    </label>
                  </span>
                  <CreditCardIcon />
                </div>
              </>
            ) : (
              <>
                {/* Stripe payment options */}
                <div className="form-control flex flex-row items-center justify-between">
                  <span className="form-control flex flex-row items-center justify-between">
                    <input
                      onClick={() => setPaymentType("stripe")}
                      id="stripe-payment"
                      type="radio"
                      name="payment-type"
                      className="radio checked:bg-primary"
                      defaultChecked
                    />
                    <label htmlFor="stripe-payment" className="label ml-2 cursor-pointer">
                      <span className="label-text">{t("payWithStripe")}</span>
                    </label>
                  </span>
                  <CreditCardIcon />
                </div>
              </>
            )}
          </div>
          {paymentType === "eBanking" && (
            <div className="max-w-lg">
              <div className="mx-auto my-4 grid w-full max-w-4xl grid-cols-2 gap-4 md:grid-cols-3">
                {paymentMethods.map((paymentMethod) => (
                  <div key={paymentMethod.name} className="flex items-center justify-center">
                    <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                      <a
                        aria-label={paymentMethod.name}
                        className={`btn h-20 w-32 cursor-pointer bg-white hover:border-primary hover:bg-white ${
                          selectedPaymentMethod?.code === paymentMethod?.code
                            ? "border border-secondary bg-white"
                            : "flex rounded-lg border border-gray-200 p-4 opacity-50 shadow-sm"
                        }`}
                        onClick={() => onPaymentMethodSelect(paymentMethod.name)}
                      >
                        <img src={paymentMethod.logoUrl} alt={paymentMethod.name} className="max-w-64 m-2 max-h-8" />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mx-4 mt-8 mb-4 flex flex-col justify-center">
                <button
                  onClick={onPay}
                  disabled={loadingPaymentMethods || newOrderLoading || !selectedPaymentMethod}
                  className={`btn-primary btn w-full rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-base-100 hover:bg-base-300 focus:outline-none focus:ring-2 focus:ring-base-300 focus:ring-offset-2 focus:ring-offset-gray-50 sm:order-last sm:w-auto ${
                    loadingPaymentMethods || newOrderLoading || loading ? "loading" : ""
                  }`}
                >
                  {`${t("pay")} ${formatPrice(round(discountedAmount) || marketPrice)}`}
                </button>
              </div>
            </div>
          )}
          {paymentType === "creditCard" && (
            <div className="px-6 pt-8">
              <div className="max-w-lg">
                <div className="mb-4 flex flex-col justify-center">
                  <button
                    onClick={onPay}
                    disabled={loading || newOrderLoading}
                    className={`btn-primary btn w-full rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-base-100 hover:bg-base-300 focus:outline-none focus:ring-2 focus:ring-base-300 focus:ring-offset-2 focus:ring-offset-gray-50 sm:order-last sm:w-auto ${
                      newOrderLoading || loading ? "loading" : ""
                    }`}
                  >
                    {`${t("pay")} ${formatPrice(round(discountedAmount) || marketPrice)}`}
                  </button>
                </div>
              </div>
            </div>
          )}
          {paymentType === "mobilePay" && (
            <div className="mt-8 px-6">
              <button
                type="button"
                className={`btn-primary btn mt-2 flex w-full items-center justify-center rounded-md border border-transparent bg-black py-3 text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 ${
                  newOrderLoading || loading ? "loading" : ""
                }`}
                disabled={loading || newOrderLoading}
                onClick={onPay}
              >
                <span className="sr-only">Pay with Apple Pay</span>
                <svg className="h-4 w-auto" fill="currentColor" viewBox="0 0 50 20">
                  <path d="M9.536 2.579c-.571.675-1.485 1.208-2.4 1.132-.113-.914.334-1.884.858-2.484C8.565.533 9.564.038 10.374 0c.095.951-.276 1.884-.838 2.579zm.829 1.313c-1.324-.077-2.457.751-3.085.751-.638 0-1.6-.713-2.647-.694-1.362.019-2.628.79-3.323 2.017-1.429 2.455-.372 6.09 1.009 8.087.676.99 1.485 2.075 2.552 2.036 1.009-.038 1.409-.656 2.628-.656 1.228 0 1.58.656 2.647.637 1.104-.019 1.8-.99 2.475-1.979.771-1.122 1.086-2.217 1.105-2.274-.02-.019-2.133-.828-2.152-3.263-.02-2.036 1.666-3.007 1.742-3.064-.952-1.408-2.437-1.56-2.951-1.598zm7.645-2.76v14.834h2.305v-5.072h3.19c2.913 0 4.96-1.998 4.96-4.89 0-2.893-2.01-4.872-4.885-4.872h-5.57zm2.305 1.941h2.656c2 0 3.142 1.066 3.142 2.94 0 1.875-1.142 2.95-3.151 2.95h-2.647v-5.89zM32.673 16.08c1.448 0 2.79-.733 3.4-1.893h.047v1.779h2.133V8.582c0-2.14-1.714-3.52-4.351-3.52-2.447 0-4.256 1.399-4.323 3.32h2.076c.171-.913 1.018-1.512 2.18-1.512 1.41 0 2.2.656 2.2 1.865v.818l-2.876.171c-2.675.162-4.123 1.256-4.123 3.159 0 1.922 1.495 3.197 3.637 3.197zm.62-1.76c-1.229 0-2.01-.59-2.01-1.494 0-.933.752-1.475 2.19-1.56l2.562-.162v.837c0 1.39-1.181 2.379-2.743 2.379zM41.1 20c2.247 0 3.304-.856 4.227-3.454l4.047-11.341h-2.342l-2.714 8.763h-.047l-2.714-8.763h-2.409l3.904 10.799-.21.656c-.352 1.114-.923 1.542-1.942 1.542-.18 0-.533-.02-.676-.038v1.779c.133.038.705.057.876.057z" />
                </svg>
                {(loading || newOrderLoading) && <SpinnerIcon className="ml-2 h-4 w-4" />}
              </button>
            </div>
          )}
          {paymentType === "stripe" && (
            <div className="max-w-lg px-6 pt-8">
              <div className="max-w-lg">
                <div className="mb-4 flex flex-col justify-center">
                  <p className="mb-8 text-sm text-gray-500">{t("stripePaymentDescription")}</p>
                  <button
                    onClick={onPay}
                    disabled={loading || stripePaymentLoading}
                    className={`btn-primary btn w-full rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm hover:border-base-100 hover:bg-base-300 hover:bg-base-300 focus:outline-none focus:ring-2 focus:ring-base-300 focus:ring-offset-2 focus:ring-offset-gray-50 sm:order-last sm:w-auto ${
                      stripePaymentLoading || loading ? "loading" : ""
                    }`}
                  >
                    {`${t("pay")} ${formatPrice(round(discountedAmount) || marketPrice)}`}
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="align-center mb-6 mt-4 flex flex-col items-center">
            <div className="mb-4">
              {discountAmount && (
                <div className="flex flex-col text-center">
                  {(paymentType === "mobilePay" || paymentType === "stripe") && (
                    <span className="pt-2 text-xl font-semibold">{`${formatPrice(round(discountedAmount))}`}</span>
                  )}
                  <div className="flex justify-center">
                    <span className={`font-normal text-gray-400 line-through`}>
                      {`${formatPrice(round(marketPrice))}`}
                    </span>
                    <span className="ml-2 text-green-600 opacity-60">{`(${formatPercentage(discountAmount)} ${t(
                      "off",
                    )})`}</span>
                  </div>
                </div>
              )}
              {!discountAmount && paymentType === "mobilePay" && (
                <span className="pt-2 text-xl font-semibold">{`${formatPrice(round(marketPrice))}`}</span>
              )}
            </div>
            <div className="border-t border-gray-200 sm:flex sm:items-center sm:justify-between">
              {!showDiscountInput && (
                <p
                  onClick={() => setShowDiscountInput(!showDiscountInput)}
                  className="mt-4 text-center text-xs text-gray-500 underline  sm:text-left"
                >
                  {t("gotDiscount")}
                </p>
              )}
            </div>
            <div className={`mt-2 ${!showDiscountInput ? "hidden" : "block"}`}>
              <DiscountInput
                eventId={eventId}
                onNewDiscount={(discountCode, discountAmount) => {
                  setDiscountCode(discountCode.trim().toUpperCase());
                  setDiscountAmount(discountAmount);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};
