import { FC, useState, useCallback, useEffect, useRef, useContext } from "react";
import { useTranslation } from "@helpers/useTranslation";
import LightGallery from "lightgallery/react";
import lgVideo from "lightgallery/plugins/video";
import lgZoom from "lightgallery/plugins/zoom";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import { CheckIcon } from "@components/Icons/Check";
import { OverlaySpinner } from "@components/Loading/OverlaySpinner";
import { LargeGridIcon } from "@components/Icons/LargeGrid";
import { SmallGridIcon } from "@components/Icons/SmallGrid";
import { DeleteIcon } from "@components/Icons/Delete";
import { EditIcon } from "@components/Icons/Edit";
import { EyeIcon } from "@components/Icons/EyeIcon";
import { SelectedIcon } from "@components/Icons/Selected";
import { UnselectedIcon } from "@components/Icons/Unselected";
import { ConfirmModal } from "@components/ConfirmModal";
import { FirebaseContext } from "@helpers/firebase";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { useToast } from "@helpers/useToast";
import { downloadAsBlob } from "@helpers/download";
import { deleteDoc } from "firebase/firestore";
import { deleteField } from "firebase/firestore";
import { doc, updateDoc } from "firebase/firestore";
import { getMediaUrl } from "@helpers/getMediaUrl";
import { PLACEHOLDER_IMAGE } from "./PlaceholderImage";
import "./LightGallery.css";

type Props = {
  challenges: Challenge[];
  teams: Team[];
  eventName: string;
  eventId: string;
  allowDownload: boolean;
  allowDelete?: boolean;
};

export const Pictures: FC<Props> = ({ challenges, teams, eventId, allowDownload }) => {
  const [teamsSelected, setTeamsSelected] = useState<Team[]>(teams);
  const { t } = useTranslation("admin");
  const [downloading, setDownloading] = useState(false);
  const lightGalleryRef = useRef(null);
  const [loadedImages, setLoadedImages] = useState<Record<string, boolean>>({});
  const [isDenseGrid, setIsDenseGrid] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentImageUri, setCurrentImageUri] = useState<string | null>(null);
  const [selectedImages, setSelectedImages] = useState<Set<string>>(new Set());
  const [isLgContainerVisible, setIsLgContainerVisible] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [bulkDeleteMode, setBulkDeleteMode] = useState(false);
  const { storage } = useContext(FirebaseContext);

  // New pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(40); // Default items per page

  // Clean up LightGallery instance on unmount
  useEffect(() => {
    return () => {
      try {
        if (lightGalleryRef.current) {
          const lgInstance = lightGalleryRef.current;
          // Ensure we're not already destroyed
          if (lgInstance.el && document.body.contains(lgInstance.el)) {
            lgInstance.destroy();
          }
        }
      } catch (error) {
        console.warn("Error cleaning up LightGallery:", error);
      }
    };
  }, []);

  const { firestore } = useContext(FirebaseContext);

  const handleDelete = async (uri: string) => {
    // uri comes in the shape of:
    // "events/${eventId}/teams/${team.id}/solutions/${solutionId}" for solutions
    // "events/${eventId}/teams/${team.id}/extras/${extraId}" for extras
    const components = uri.split("/");
    const isSolution = components[4] === "solutions";
    const isExtra = components[4] === "extras";
    const teamId = components[3];
    const imageId = components[components.length - 1];
    const cleanImageId = imageId.replace(/_light$/, "");

    try {
      if (isSolution) {
        // Remove from map field
        const teamDocRef = doc(firestore, `events/${eventId}/teams/${teamId}`);
        await updateDoc(teamDocRef, {
          [`solutionsMap.${cleanImageId}`]: deleteField(),
        });

        // Remove from collection (future proofing)
        const docRef = doc(firestore, `events/${eventId}/teams/${teamId}/solutions/${cleanImageId}`);
        await deleteDoc(docRef);
      }

      if (isExtra) {
        // Remove from map field
        const teamDocRef = doc(firestore, `events/${eventId}/teams/${teamId}`);
        await updateDoc(teamDocRef, {
          [`extrasMap.${cleanImageId}`]: deleteField(),
        });

        // Remove from collection (future proofing)
        const docRef = doc(firestore, `events/${eventId}/teams/${teamId}/extras/${cleanImageId}`);
        await deleteDoc(docRef);
      }
    } catch (error) {
      console.error(error);
    }

    toast(t("deleted"), "✏️", "success", 1000);
  };

  const handleImageError = useCallback((imageUri: string) => {
    setLoadedImages((prev) => ({
      ...prev,
      [imageUri]: false,
    }));
  }, []);

  const handleImageLoad = useCallback((imageUri: string) => {
    setLoadedImages((prev) => ({
      ...prev,
      [imageUri]: true,
    }));
  }, []);

  const toggleTeam = (team: Team) => {
    if (allSelected()) {
      setTeamsSelected([team]);
      return;
    }

    if (teamsSelected.includes(team)) {
      const newTeams = teamsSelected.filter((t) => t.id !== team.id);
      setTeamsSelected(newTeams);
    } else {
      const newTeams = [team, ...teamsSelected];
      setTeamsSelected(newTeams);
    }
  };

  const isSelected = (team: Team) => {
    return teamsSelected.find((t) => t.id === team.id);
  };

  const allSelected = () => {
    return teamsSelected.length === teams.length;
  };

  const toggleAll = () => {
    if (allSelected()) {
      setTeamsSelected([]);
    } else {
      setTeamsSelected(teams);
    }
  };

  const toast = useToast();
  const { functions } = useContext(FirebaseContext);
  const [downloadPhoto] = useHttpsCallable(functions, "downloadPhoto");
  const download = async ({ uri, filename }) => {
    setDownloading(true);
    try {
      const result = await downloadPhoto({ uri, filename, eventId });
      const url = result?.data as string;
      downloadAsBlob(url, filename);
      toast(t("photoDownloaded"), "📸", "success");
    } catch (error) {
      console.error(error);
      toast(t("errorDownloading"), "🤷‍♂️", "error");
    } finally {
      setDownloading(false);
    }
  };

  const getAllGalleryItems = useCallback(() => {
    return teams.reduce<JSX.Element[]>((items, team) => {
      if (!isSelected(team)) return items;

      const solutions = Object.keys(team.solutionsMap || {});
      const extras = Object.keys(team.extrasMap || {});

      const solutionItems = solutions
        .filter((solution) => !solution.endsWith("-video"))
        .map((solution) => {
          const hasVideo =
            solutions.findIndex((s) => s.endsWith("-video") && s.startsWith(solution.substring(0, 8))) > -1;

          const imageUri = `events/${eventId}/teams/${team.id}/solutions/${solution}_light`;
          const videoUri = `events/${eventId}/teams/${team.id}/solutions/${solution}.mp4`;

          const imageUrl = getMediaUrl(storage, imageUri);
          const videoUrl = hasVideo ? getMediaUrl(storage, videoUri) : null;

          const challenge = challenges.find((c) => c.id === solution)?.title || "";
          const title = `${team.displayName ?? team.name}${challenge ? ` - ${challenge}` : ""}`;

          const hdImageUri = `events/${eventId}/teams/${team.id}/solutions/${solution}_heavy`;

          // Skip this item if we've tried to load it and it failed
          if (loadedImages[imageUri] === false) return null;

          if (hasVideo) {
            return (
              <a
                key={imageUri}
                data-hd-uri={videoUri}
                data-name={solution}
                data-type="video"
                data-lg-size="1280-720"
                className="gallery-item flex aspect-square w-full items-center justify-center"
                data-video={JSON.stringify({
                  source: [{ src: videoUrl, type: "video/mp4" }],
                  attributes: {
                    preload: false,
                    playsinline: true,
                    controls: true,
                  },
                })}
                data-poster={imageUrl || PLACEHOLDER_IMAGE}
                data-sub-html={`<h4>${title}</h4>`}
              >
                <img
                  src={imageUrl || PLACEHOLDER_IMAGE}
                  alt={title}
                  className={`h-full w-full rounded-lg object-cover ${
                    isDenseGrid ? "aspect-square max-h-[16rem]" : "aspect-video max-h-[30rem]"
                  }`}
                  onError={() => handleImageError(imageUri)}
                  onLoad={() => handleImageLoad(imageUri)}
                />
                <div className="relative -mt-10 flex h-10 w-full flex-col items-center justify-center bg-gray-100 opacity-90">
                  <div className="text-sm font-semibold">
                    {team.displayName ?? team.name}
                    {hasVideo ? " 🍿🎞️" : null}
                  </div>
                  {challenge && <div className="overflow-hidden text-sm font-light">{challenge}</div>}
                </div>
              </a>
            );
          }

          return (
            <a
              key={imageUri}
              data-lg-size="1280-720"
              className="gallery-item flex aspect-square w-full items-center justify-center"
              data-src={imageUrl || PLACEHOLDER_IMAGE}
              data-hd-uri={hdImageUri}
              data-name={solution}
              data-type="image"
              data-sub-html={`<h4>${title}</h4>`}
              {...(hasVideo &&
                videoUrl && {
                  "data-video": JSON.stringify({
                    source: [{ src: videoUrl, type: "video/mp4" }],
                    attributes: { preload: false, controls: true },
                  }),
                })}
            >
              <img
                src={imageUrl || PLACEHOLDER_IMAGE}
                alt={title}
                className={`h-full w-full rounded-lg object-cover ${
                  isDenseGrid ? "aspect-square max-h-[16rem]" : "aspect-video max-h-[30rem]"
                }`}
                onError={() => handleImageError(imageUri)}
                onLoad={() => handleImageLoad(imageUri)}
              />
              <div className="relative -mt-10 flex h-10 w-full flex-col items-center justify-center bg-gray-100 opacity-90">
                <div className="text-sm font-semibold">
                  {team.displayName ?? team.name}
                  {hasVideo ? " 🍿🎞️" : null}
                </div>
                {challenge && <div className="overflow-hidden text-sm font-light">{challenge}</div>}
              </div>
              {isEditMode && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleDeleteClick(imageUri);
                  }}
                  className="absolute top-2 right-2 rounded-full bg-red-500 p-2 text-white transition-opacity duration-200 hover:bg-red-600"
                  title={t("delete")}
                >
                  <DeleteIcon className="h-5 w-5" />
                </button>
              )}
            </a>
          );
        })
        .filter(Boolean); // Remove null items

      const extraItems = extras
        .map((extra) => {
          const imageUri = `events/${eventId}/teams/${team.id}/extras/${extra}_light`;
          const hdImageUri = `events/${eventId}/teams/${team.id}/extras/${extra}_heavy`;
          const imageUrl = getMediaUrl(storage, imageUri);
          const title = team.displayName ?? team.name;

          // Skip this item if we've tried to load it and it failed
          if (loadedImages[imageUri] === false) return null;

          return (
            <a
              key={imageUri}
              data-hd-uri={hdImageUri}
              data-type="image"
              data-name={extra}
              className="gallery-item flex aspect-square w-full items-center justify-center"
              data-src={imageUrl || PLACEHOLDER_IMAGE}
              data-sub-html={`<h4>${title}</h4>`}
            >
              <img
                src={imageUrl || PLACEHOLDER_IMAGE}
                alt={title}
                className={`h-full w-full rounded-lg object-cover ${
                  isDenseGrid ? "aspect-square max-h-[16rem]" : "aspect-video max-h-[30rem]"
                }`}
                onError={() => handleImageError(imageUri)}
                onLoad={() => handleImageLoad(imageUri)}
              />
              <div className="relative -mt-10 flex h-10 w-full flex-col items-center justify-center bg-gray-100 opacity-90">
                <div className="text-sm font-semibold">{title}</div>
              </div>
              {isEditMode && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleDeleteClick(imageUri);
                  }}
                  className="absolute top-2 right-2 rounded-full bg-red-500 p-2 text-white transition-opacity duration-200 hover:bg-red-600"
                  title={t("delete")}
                >
                  <DeleteIcon className="h-5 w-5" />
                </button>
              )}
            </a>
          );
        })
        .filter(Boolean); // Remove null items

      return [...items, ...solutionItems, ...extraItems];
    }, []);
  }, [teamsSelected, teams, eventId, challenges, loadedImages, handleImageError, handleImageLoad, selectedImages]);

  const onInit = useCallback((detail) => {
    lightGalleryRef.current = detail.instance;

    const lg = detail.instance;
    // Wait until the gallery is fully initialized
    lg.LGel.on("lgAfterSlide", () => {
      const downloadBtn = document.querySelector(".lg-download");

      if (downloadBtn) {
        // Add mobile-friendly classes to the download button
        downloadBtn.classList.add("lg-show-mobile");

        // @ts-ignore
        downloadBtn.onclick = async (e) => {
          e.preventDefault();
          e.stopPropagation();

          // build image URI
          const currentSlide = lg.items[lg.index];
          const mediaUri = currentSlide?.getAttribute("data-hd-uri");
          const mediaName = currentSlide?.getAttribute("data-name");
          const mediaType = currentSlide?.getAttribute("data-type");

          const extension = mediaType === "video" ? "mp4" : "jpg";
          const filename = `${eventId}-${mediaName}.${extension}`;

          // Store current index before downloading
          const currentIndex = lg.index;

          // Close gallery temporarily to prevent UI issues during download
          setIsLgContainerVisible(false);
          await download({ uri: mediaUri, filename });

          // Reopen gallery at the same slide
          setTimeout(() => {
            setIsLgContainerVisible(true);
            // Next tick to ensure the container is visible before opening
            setTimeout(() => {
              if (lightGalleryRef.current) {
                lightGalleryRef.current.openGallery(currentIndex);
              }
            }, 50);
          }, 100);
        };
      }
    });
  }, []);

  const handleDeleteClick = (imageUri: string) => {
    if (isEditMode && selectedImages.size > 1) {
      // If we're in edit mode and have multiple selections, prepare for bulk delete
      setBulkDeleteMode(true);
    } else {
      // Single image delete mode
      setBulkDeleteMode(false);
      setCurrentImageUri(imageUri);
    }
    setShowConfirmModal(true);
  };

  const confirmDelete = async () => {
    if (bulkDeleteMode) {
      // Delete all selected images
      for (const imageUri of Array.from(selectedImages)) {
        await handleDelete(imageUri);
      }
      // Clear selection after deletion
      clearImageSelection();
    } else if (currentImageUri) {
      // Delete single image
      await handleDelete(currentImageUri);
    }

    setShowConfirmModal(false);
    setCurrentImageUri(null);
    setBulkDeleteMode(false);
  };

  const cancelDelete = () => {
    setShowConfirmModal(false);
    setCurrentImageUri(null);
    setBulkDeleteMode(false);
  };

  const toggleImageSelection = (imageUri: string) => {
    setSelectedImages((prev) => {
      const newSelection = new Set(prev);
      if (newSelection.has(imageUri)) {
        newSelection.delete(imageUri);
      } else {
        newSelection.add(imageUri);
      }
      return newSelection;
    });
  };

  const isImageSelected = (imageUri: string) => {
    return selectedImages.has(imageUri);
  };

  const clearImageSelection = () => {
    setSelectedImages(new Set());
  };

  // Clear selection when exiting edit mode
  useEffect(() => {
    if (!isEditMode) {
      clearImageSelection();
    }
  }, [isEditMode]);

  // Get all items first, then paginate
  const allGalleryItems = getAllGalleryItems();

  // Calculate total pages
  const totalPages = Math.ceil(allGalleryItems.length / itemsPerPage);

  // Get current items for pagination
  const getCurrentPageItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return allGalleryItems.slice(startIndex, endIndex);
  };

  // Get items for current page
  const galleryItems = getCurrentPageItems();

  // Handle pagination navigation
  const goToPage = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Function to render pagination controls
  const renderPagination = () => {
    if (totalPages <= 1) return null;

    return (
      <div className="mt-8 mb-16 flex flex-wrap items-center justify-center gap-2 px-2">
        <button onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 1} className="btn-sm btn">
          {t("previous")}
        </button>

        <div className="flex flex-wrap justify-center gap-1">
          {Array.from({ length: Math.min(3, totalPages) }, (_, i) => {
            // Simplified logic for mobile
            let pageNum = currentPage;
            if (totalPages <= 3) {
              pageNum = i + 1;
            } else if (currentPage <= 2) {
              pageNum = i + 1;
            } else if (currentPage >= totalPages - 1) {
              pageNum = totalPages - 2 + i;
            } else {
              pageNum = currentPage - 1 + i;
            }

            return (
              <button
                key={i}
                onClick={() => goToPage(pageNum)}
                className={`btn-sm btn min-w-[2.5rem] ${pageNum === currentPage ? "btn-primary" : "btn-ghost"}`}
              >
                {pageNum}
              </button>
            );
          })}

          {totalPages > 3 && currentPage < totalPages - 1 && (
            <>
              {currentPage < totalPages - 2 && <span className="self-end pb-2">...</span>}
              <button onClick={() => goToPage(totalPages)} className="btn-ghost btn-sm btn min-w-[2.5rem]">
                {totalPages}
              </button>
            </>
          )}
        </div>

        <button onClick={() => goToPage(currentPage + 1)} disabled={currentPage === totalPages} className="btn-sm btn">
          {t("next")}
        </button>

        <div className="w-full sm:w-auto sm:pl-4">
          <select
            value={itemsPerPage}
            onChange={(e) => {
              setItemsPerPage(Number(e.target.value));
              setCurrentPage(1);
            }}
            className="select-bordered select select-sm w-full sm:w-auto"
          >
            <option value="20">20</option>
            <option value="40">40</option>
            <option value="100">100</option>
            <option value="200">200</option>
          </select>
        </div>
      </div>
    );
  };

  // Add grid classes based on density state
  const gridClasses = isDenseGrid
    ? "grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8"
    : "grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-12 max-w-[2560px] mx-auto place-items-center";

  // Add a delete selected button when in edit mode with selections
  const renderDeleteSelectedButton = () => {
    if (isEditMode && selectedImages.size > 0) {
      return (
        <div className="fixed bottom-6 right-6 z-10 flex flex-col space-y-2">
          <button
            onClick={clearImageSelection}
            className="flex items-center rounded-full bg-gray-500 px-4 py-3 text-white shadow-lg transition-all duration-200 hover:bg-gray-600"
          >
            {t("clearSelection")}
          </button>
          <button
            onClick={() => handleDeleteClick("")} // Empty string triggers bulk delete mode
            className="flex items-center rounded-full bg-red-500 px-4 py-3 text-white shadow-lg transition-all duration-200 hover:bg-red-600"
          >
            <DeleteIcon className="mr-2 h-5 w-5" />
            {`${t("deleteSelected")} (${selectedImages.size})`}
          </button>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <OverlaySpinner loading={downloading} className="bg-opacity-80" />
      <div>
        <div className="flex flex-wrap items-center justify-center pt-8 pb-4 md:py-8">
          <button
            onClick={toggleAll}
            type="button"
            className={`mx-1 mt-2 inline-flex items-center rounded-full bg-gray-50 px-3 py-1 text-sm font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 ${
              allSelected() ? "bg-base-200 font-bold ring-base-300" : "bg-base-100 font-light text-opacity-40"
            }`}
          >
            {t("allTeams")} {allSelected() ? <CheckIcon className="ml-2 h-4 w-4" /> : null}
          </button>
          {teams.map((team) => (
            <span
              key={team.id}
              onClick={() => toggleTeam(team)}
              className={`mx-1 mt-2 inline-flex items-center rounded-full bg-gray-50 px-3 py-1 text-sm font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 ${
                isSelected(team) ? "bg-base-200 font-bold ring-base-300" : "bg-base-100 font-light text-opacity-40"
              } cursor-pointer`}
            >
              {team.displayName ?? team.name} {isSelected(team) ? <CheckIcon className="ml-2 h-4 w-4" /> : null}
            </span>
          ))}
        </div>
        <div className="flex items-center justify-between py-4 lg:px-20">
          <div className="text-sm font-semibold uppercase text-neutral">
            {`${isEditMode ? t("editing") : t("showing")} ${galleryItems.length} ${t("from")} ${
              allGalleryItems.length
            } ${t("totalPhotos")}`}
            <div>
              {isEditMode && selectedImages.size > 0 && (
                <span className="rounded-full bg-base-300 px-2 py-0.5 text-xs text-white">
                  {selectedImages.size} {t("selected")}
                </span>
              )}
            </div>
          </div>

          <div className="flex items-center space-x-2">
            <button
              onClick={() => setIsEditMode(!isEditMode)}
              className="btn-ghost btn-sm btn m-0 flex items-center p-1"
              title={isEditMode ? t("viewMode") : t("editMode")}
            >
              {isEditMode ? <EyeIcon className="h-5 w-5" /> : <EditIcon className="h-5 w-5" />}
            </button>
            <button
              onClick={() => setIsDenseGrid(!isDenseGrid)}
              className="btn-ghost btn-sm btn m-0 p-1"
              title={isDenseGrid ? t("expandGrid") : t("compactGrid")}
            >
              {isDenseGrid ? <SmallGridIcon className="h-5 w-5" /> : <LargeGridIcon className="h-5 w-5" />}
            </button>
          </div>
        </div>

        {isEditMode ? (
          // Edit view with pagination only
          <div>
            <div className={`pb-8 xl:px-32 ${gridClasses} justify-items-center`}>
              {galleryItems.map((item, index) => {
                // Extract needed properties from the gallery item
                const src = item.props["data-src"] || item.props["data-poster"];
                const title = item.props["data-sub-html"] ? item.props["data-sub-html"].replace(/<\/?h4>/g, "") : "";
                const imageUri = item.key;
                const isSelected = isImageSelected(imageUri);

                return (
                  <div
                    key={index}
                    className="relative flex aspect-square w-full cursor-pointer items-center justify-center"
                    onClick={() => toggleImageSelection(imageUri)}
                  >
                    <img
                      src={src || PLACEHOLDER_IMAGE}
                      alt={title}
                      className={`h-full w-full rounded-lg object-cover ${
                        isDenseGrid ? "aspect-square max-h-[16rem]" : "aspect-video max-h-[30rem]"
                      } ${isSelected ? "ring-2 ring-primary ring-offset-2" : ""}`}
                    />
                    <div className="absolute bottom-0 flex h-10 w-full flex-col items-center justify-center bg-gray-100 opacity-90">
                      <div className="text-sm font-semibold">
                        {title.split(" - ")[0]}
                        {item.props["data-video"] ? " 🍿🎞️" : null}
                      </div>
                      {title.includes(" - ") && (
                        <div className="overflow-hidden text-sm font-light">{title.split(" - ")[1]}</div>
                      )}
                    </div>

                    {/* Selection button in top left */}
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent double-toggling when clicking the button directly
                        toggleImageSelection(imageUri);
                      }}
                      className="absolute top-2 left-2 rounded-full bg-white p-2 text-base-300 shadow-md transition-all duration-200 hover:bg-gray-100"
                      title={isSelected ? t("deselect") : t("select")}
                    >
                      {isSelected ? <SelectedIcon className="h-5 w-5" /> : <UnselectedIcon className="h-5 w-5" />}
                    </button>

                    {/* Delete button in top right */}
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleDeleteClick(imageUri);
                      }}
                      className="absolute top-2 right-2 rounded-full bg-red-500 p-2 text-white transition-opacity duration-200 hover:bg-red-600"
                      title={t("delete")}
                    >
                      <DeleteIcon className="h-5 w-5" />
                    </button>
                  </div>
                );
              })}
            </div>
            {renderPagination()}
          </div>
        ) : (
          // Gallery view with pagination only
          <div>
            <div className={`pb-8 xl:px-32 ${isLgContainerVisible ? "" : "hidden"}`}>
              <LightGallery
                elementClassNames={`${gridClasses} justify-items-center`}
                onInit={onInit}
                plugins={[lgVideo, lgZoom, lgThumbnail]}
                mode="lg-slide"
                speed={200}
                startClass=""
                startAnimationDuration={0}
                download={allowDownload}
                share={true}
                strings={{
                  download: t("downloadMedia"),
                  closeGallery: t("close"),
                  toggleMaximize: t("toggleMaximize"),
                  previousSlide: t("previous"),
                  nextSlide: t("next"),
                  playVideo: t("playVideo"),
                  mediaLoadingFailed: t("mediaLoadingFailed"),
                }}
                thumbnail={true}
                zoom={true}
                videoMaxSize={"40"}
                allowMediaOverlap={true}
                closable={true}
                loop={true}
                counter={true}
                progressBar={true}
                backdropDuration={0}
                hideControlOnEnd={false}
                zoomFromOrigin={false}
                licenseKey="your-license-key-here"
                mobileSettings={{
                  controls: true,
                  showCloseIcon: true,
                  download: true,
                }}
                addClass="lg-toolbar-visible"
                controls={true}
                showCloseIcon={true}
                autoplayControls={false}
              >
                {galleryItems}
              </LightGallery>
            </div>
            {renderPagination()}
          </div>
        )}
      </div>

      {/* Render the delete selected floating button */}
      {renderDeleteSelectedButton()}

      <ConfirmModal
        show={showConfirmModal}
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
        messageCopy={
          bulkDeleteMode
            ? t("areYouSureYouWantToDeleteNImages", { count: selectedImages.size })
            : t("areYouSureYouWantToDeleteThisImage")
        }
        actionCopy={t("delete")}
      />
    </>
  );
};
