import { Loading } from "@components/Loading";
import { TopNav } from "@components/TopNav";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "@helpers/useTranslation";
import { useNavigate } from "react-router-dom";
import { FirebaseContext } from "src/helpers/firebase";
import { useEventId } from "src/helpers/useEvent";
import { usePersistedState } from "src/helpers/usePersistedState";
import { useToast } from "@helpers/useToast";

export const PaymentsCancel = () => {
  const { t } = useTranslation("admin");
  const eventId = useEventId();
  const { auth } = useContext(FirebaseContext);
  const navigate = useNavigate();
  const [appData, setAppData] = usePersistedState();
  const [seconds, setSeconds] = useState(7);
  const toast = useToast();

  useEffect(() => {
    toast(t("paymentCancelled"), "🚫", "error");
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const returnBack = () => {
    const redirectBackUrl = appData?.redirectBackUrl;
    setAppData({
      ...appData,
      uid: auth.currentUser?.uid,
      isAdmin: true,
      orderUuid: undefined, // Clear order info
      orderId: undefined, // Clear order info
      stripeSessionId: undefined, // Clear Stripe session info
    });

    redirectBackUrl ? navigate(redirectBackUrl) : navigate(`/${eventId}/admin`);
  };

  useEffect(() => {
    if (seconds <= 0) returnBack();
  }, [seconds]);

  return (
    <div className="flex flex-col">
      <Loading loading={false}>
        <TopNav isAdmin />
        <div className="flex h-screen flex-col overflow-hidden">
          <main className="flex-1 overflow-y-scroll p-4">
            <div className="flex flex-col items-center justify-center">
              <h1 className="mt-12 text-center text-2xl">{t("paymentCancelled")}</h1>
              <div className="mt-4 text-center text-lg">{t("paymentCancelledMessage")}</div>
              <button onClick={returnBack} className="btn-primary btn-ghost btn mt-12">{`${t(
                "goBackFromPayments",
              )} ${seconds}`}</button>
            </div>
          </main>
        </div>
      </Loading>
    </div>
  );
};
