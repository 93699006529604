import { FC } from "react";
import { IconProps } from "./IconProps";

// Box icon without checkmark
export const UnselectedIcon: FC<IconProps> = ({ className, strokeWidth }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={strokeWidth ?? 1.5}
    stroke="currentColor"
    className={`${className ? className : "h-6 w-6"}`}
  >
    <rect width="24" height="24" rx="4" fill="currentColor" />
  </svg>
);
