import { useContext, useEffect, useMemo, useState } from "react";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { FirebaseContext } from "@helpers/firebase";
import { downloadAsFetchLink } from "@helpers/download";
import { useToast } from "@helpers/useToast";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { OverlaySpinner } from "@components/Loading/OverlaySpinner";

const filterCaseInsensitive = (filter, row) => {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
};

export const EventTable = ({ data }: { data: EventSummary[] }) => {
  const { functions } = useContext(FirebaseContext);
  const [generateInvoice, generatingInvoice, error] = useHttpsCallable<
    API.GenerateInvoiceRequest,
    API.GenerateInvoiceResponse
  >(functions, "generateInvoice");
  const toast = useToast();

  useEffect(() => {
    if (error) toast("something went wrong", "🤷‍♂️", "error");
  }, [error]);

  const onDownloadInvoice = async (eventId: string) => {
    const response = await generateInvoice({ eventId });
    downloadAsFetchLink(response.data.url, `tuokis-${eventId}-invoice.pdf`);
  };

  const formatDate = (dateStr: string) => {
    if (!dateStr) return "";
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const isWithin30Days = (dateStr: string) => {
    if (!dateStr) return false;
    const today = new Date();
    const date = new Date(dateStr);
    // @ts-ignore
    const isNotInPast = date > today.setDate(today.getDate() - 1);
    // @ts-ignore
    const isNotLaterThan30DaysFromNow = date < today.setDate(today.getDate() + 30);

    return isNotInPast && isNotLaterThan30DaysFromNow;
  };

  const sortedData = useMemo(() => {
    return [...data].sort((a, b) => {
      const adate = a.date ? new Date(a.date as string) : new Date("1970-01-01");
      const bdate = b.date ? new Date(b.date as string) : new Date("1970-01-01");
      // @ts-ignore
      return bdate - adate;
    });
  }, [data]);

  const [showPremium, setShowPremium] = useState(true);
  const [showUpcoming, setShowUpcoming] = useState(false);

  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    if (sortedData) {
      const tableData = sortedData
        .map((item) => {
          const { id, isPremium, date, numberOfTeams, numberOfSolutions, numberOfExtras } = item;
          return {
            id,
            date: formatDate(date),
            numberOfTeams,
            numberOfSolutions,
            numberOfExtras,
            isPremium: isPremium ? "YES" : "",
            actions: (
              <div className="actions-right">
                <button
                  className={`btn-sm btn my-0 p-1 ${generatingInvoice ? "loading" : ""}`}
                  onClick={() => onDownloadInvoice(id)}
                  disabled={!isPremium || generatingInvoice}
                >
                  Download
                </button>
              </div>
            ),
          };
        })
        .filter((item) => {
          if (showPremium) return item.isPremium === "YES";
          return true;
        })
        .filter((item) => {
          if (showUpcoming) return isWithin30Days(item.date);
          return true;
        });
      setTableData(tableData);
    }
  }, [sortedData, showPremium, showUpcoming]);
  return (
    <div className="mx-2 flex flex-col justify-center p-4 sm:mx-12">
      <OverlaySpinner loading={generatingInvoice} />
      <div className="mb-4 flex max-w-sm flex-col">
        <div className="form-control mt-4">
          <label className="label cursor-pointer" htmlFor={"upcoming"}>
            <div className="label-text mr-3 text-sm">
              <span className="label-text">Upcoming (within 30 days)</span>
            </div>
            <input
              id={"upcoming"}
              type="checkbox"
              className={`toggle-primary toggle`}
              checked={showUpcoming}
              onClick={(e) => {
                const value = (e.target as HTMLInputElement).checked;
                setShowUpcoming(value);
              }}
            />
          </label>
        </div>
        <div className="form-control">
          <label className="label cursor-pointer" htmlFor={"upcoming"}>
            <div className="label-text mr-3 text-sm">
              <span className="label-text">Only premium</span>
            </div>
            <input
              id={"upcoming"}
              type="checkbox"
              className={`toggle-primary toggle`}
              checked={showPremium}
              onClick={(e) => {
                const value = (e.target as HTMLInputElement).checked;
                setShowPremium(value);
              }}
            />
          </label>
        </div>
      </div>
      <ReactTable
        data={tableData}
        filterable
        defaultFilterMethod={filterCaseInsensitive}
        columns={[
          {
            Header: "ID",
            accessor: "id",
            width: 150,
          },
          {
            Header: "Date",
            accessor: "date",
            minWidth: 120,
          },
          {
            Header: "Teams",
            accessor: "numberOfTeams",
            maxWidth: 80,
          },
          {
            Header: "Solutions",
            accessor: "numberOfSolutions",
            maxWidth: 80,
          },
          {
            Header: "Extras",
            accessor: "numberOfExtras",
            maxWidth: 80,
          },
          {
            Header: "Premium",
            accessor: "isPremium",
            default: "YES",
            minWidth: 100,
          },
          {
            Header: "Invoice",
            accessor: "actions",
            sortable: false,
            filterable: false,
            maxWidth: 200,
          },
        ]}
        defaultPageSize={10}
        showPaginationBottom
        className="w-full text-center"
      />
    </div>
  );
};

export default EventTable;
